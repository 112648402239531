import gql from 'graphql-tag'

export const WHOAMI_QUERY = gql`
query WhoAmI {
  whoAmI {
    id
    username
    firstName
    lastName
    email
    isStaff
    cdbAccessKey
    organisation {
      id
      name
      cdbAccessKey
      dvkgUrl
    }
    permissions {
      codename
    }
    dmVersionKey
    gmCdbUrl
  }
}
`

export const TOKENAUTH_MUTATION = gql`
mutation TokenAuth($username: String!, $password: String!) {
  tokenAuth(username: $username, password: $password){
    token
  }
}
`