<template>
  <div>
    <div class="mytrip-settings">
      <div class="block-title">
        {{ $t("main.MYJOURNEY_PAGE") }}
      </div>

      <v-snackbar v-model="showSavedMessage" :timeout="2000" color="success">{{ $t('main.SAVED') }}</v-snackbar>
      <div class="mytrip-category-list">

        <v-list min-width="400px">
          <template v-for="(item, index) in listItems">
            <v-list-item :key="`item-${item.modelKey}`">
              <v-list-item-content>{{ $t(item.translationKey) }}</v-list-item-content>
              <v-list-item-action>
                <v-checkbox v-model="formOrganisation[item.modelKey]" class="mytrip-category-checkbox"/>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < listItems.length - 1" :key="`divider-${item.modelKey}`"></v-divider>
          </template>

          <div class="text-right">
            <v-btn color="primary" @click="saveOrganisation">{{ $t("main.SAVE_ACTION") }}</v-btn>
          </div>
        </v-list>

        <p class="intro-text px-4" v-html="$t('main.PROFILE_PAGE_MY_TRIP')" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: 'MyTripFilterSettingsView',

  mounted() {
    this.loadOrganisation()
  },
  data() {
    return {
      showSavedMessage: false,

      formOrganisation: {
        myjourneyWorthKnowing: false,
        myjourneyCaseOfEmergency: false,
        myjourneyOnSiteRepresentatives: false,
        myjourneyTraffic: false,
        myjourneyMoney: false,
        myjourneyCriminalLaws: false,
        myjourneyMedicalCare: false
      },

      listItems: [
        { translationKey: "detailgroup.CaseOfEmergency", modelKey: "myjourneyCaseOfEmergency" },
        { translationKey: "detailgroup.EnduserWorthKnowing", modelKey: "myjourneyWorthKnowing" },
        { translationKey: "detailgroup.MedicalCare", modelKey: "myjourneyMedicalCare"},
        { translationKey: "detailgroup.Money", modelKey: "myjourneyMoney" },
        { translationKey: "detailgroup.Traffic", modelKey: "myjourneyTraffic" },
        { translationKey: "detailgroup.LocalCriminalLaws", modelKey: "myjourneyCriminalLaws" },
        { translationKey: "detailgroup.OnSiteRepresentatives", modelKey: "myjourneyOnSiteRepresentatives" },
      ]
    }
  },
  computed: {
    ...mapState({
      authOrganisation: state => state.authStore.organisation,
    }),
    ...mapGetters({
      organisationGetById: 'organisationStore/getById'
    }),

    organisation() {
      if (!this.authOrganisation || !this.authOrganisation.id) {
        return null
      }
      return this.organisationGetById(this.authOrganisation.id)
    },
  },
  methods: {
    ...mapActions({
      organisationFetch: 'organisationStore/fetch',
      organisationUpdate: 'organisationStore/update',
    }),

    loadOrganisation() {
      if (this.authOrganisation && this.authOrganisation.id) {
        this.organisationFetch({
          variables: {
            id: this.authOrganisation.id
          },
          force: true
        }).then(() => {
          this.fillOrganisationForm()
        })
      }
    },

    fillOrganisationForm() {
      if (this.organisation && this.organisation.isDone) {
        this.formOrganisation.myjourneyWorthKnowing = this.organisation.data.myjourneyWorthKnowing
        this.formOrganisation.myjourneyCaseOfEmergency = this.organisation.data.myjourneyCaseOfEmergency
        this.formOrganisation.myjourneyOnSiteRepresentatives = this.organisation.data.myjourneyOnSiteRepresentatives
        this.formOrganisation.myjourneyTraffic = this.organisation.data.myjourneyTraffic
        this.formOrganisation.myjourneyMoney = this.organisation.data.myjourneyMoney
        this.formOrganisation.myjourneyCriminalLaws = this.organisation.data.myjourneyCriminalLaws
        this.formOrganisation.myjourneyMedicalCare = this.organisation.data.myjourneyMedicalCare
      }
    },

    saveOrganisation() {

      let updateData = {
          id: this.authOrganisation.id,
          name: this.organisation.data.name,
          publicName: this.organisation.data.publicName,
          phone: this.organisation.data.phone,
          fax: this.organisation.data.fax,
          email: this.organisation.data.email,
          website: this.organisation.data.website,
          street: this.organisation.data.street,
          zipCode: this.organisation.data.zipCode,
          city: this.organisation.data.city,
          countryIso2: this.organisation.data.countryIso2,
          myjourneyWorthKnowing : this.formOrganisation.myjourneyWorthKnowing,
          myjourneyCaseOfEmergency : this.formOrganisation.myjourneyCaseOfEmergency,
          myjourneyOnSiteRepresentatives : this.formOrganisation.myjourneyOnSiteRepresentatives,
          myjourneyTraffic : this.formOrganisation.myjourneyTraffic,
          myjourneyMoney: this.formOrganisation.myjourneyMoney,
          myjourneyCriminalLaws: this.formOrganisation.myjourneyCriminalLaws,
          myjourneyMedicalCare: this.formOrganisation.myjourneyMedicalCare
        }

        this.organisationUpdate({
          variables: {
            input: updateData
          }
        }).then(() => {
          this.showSavedMessage = true
        })
    },

  },
  watch: {
    authOrganisation() {
      this.loadOrganisation()
    },
  }
}
</script>

<style scoped>

  .mytrip-settings {
    background-color: #FFFFFF;
    padding: 18px;
    border-radius: 0.625rem;
    margin-bottom: 2.7%;
  }

  .intro-text {
    margin-bottom: 2rem;
    color: grey;
  }

  .mytrip-category-list {
    max-width: 800px;
    display: flex;
  }

  .mytrip-category-checkbox{
    padding: 0;
    margin-left: 47%;
  }
</style>